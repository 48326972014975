import React, { PureComponent, Fragment } from 'react'
import CompanyProfileDesktop from './CompanyProfile/CompanyProfileDesktop'
import { connect } from 'react-redux'
import { receivedCompanyProfileData } from '../Resources/CompanyProfile/Data'
import { fetchCompanyProfileApi } from '../Resources/CompanyProfile/Api'
import { getBookingWindowsForDateApi } from './Redux/BookingWindowsForSelectedDate/Api'
import { updateBookingWindows } from './Redux/BookingWindowsForSelectedDate/Actions'
import ExecutionEnvironment from 'src/common/ExecutionEnvironment'

const updateCowbellPreview = (
  receivedCompanyProfileData,
  updateBookingWindows
) => event => {
  const data = event.data

  if (data && data.type === 'REFRESH_COWBELL_PREVIEW') {
    fetchCompanyProfileApi()
      .then(response => {
        receivedCompanyProfileData(response.data)
      })
      .then(response => {
        const anchorElement = document.getElementById(`${data.anchor}`)
        const anchorTopPosition = !!anchorElement ? anchorElement.offsetTop : 0

        window.scrollTo(0, anchorTopPosition)
      })

    getBookingWindowsForDateApi({ show_for_days: 1 }).then(response => {
      updateBookingWindows(response.data.start_date, response.data.booking_windows.data)
    })
  }
}

@connect(
  null,
  { receivedCompanyProfileData, updateBookingWindows }
)
export default class Preview extends PureComponent {
  componentDidMount() {
    window.addEventListener(
      'message',
      updateCowbellPreview(
        this.props.receivedCompanyProfileData,
        this.props.updateBookingWindows
      ),
      false
    )
  }

  render() {
    if (ExecutionEnvironment.onClient) {
      return (
        <Fragment>
          <div data-rerender="force_tree_rerender" />
          <CompanyProfileDesktop />
        </Fragment>
      )
    } else {
      return <CompanyProfileDesktop />
    }
  }
}
